import { Text, VStack } from '@chakra-ui/react';

import { ProductEducationPopover } from '@arena-labs/strive2-ui';

import { AciCTACard } from './aci-cta-card';

export function AciCtaWidget() {
  return (
    <VStack gap={1}>
      <Text textStyle={'copy_bold'} mr={'auto'}>
        Daily Action
      </Text>

      <ProductEducationPopover
        anchorXPaddingModifier={2}
        id={'ACI_Two'}
        placement={'top'}
        shouldRender={true}
        title={'Start Arena Check-In'}
        body={`It's not a bad idea to start by checking in with yourself!`}
      >
        <AciCTACard />
      </ProductEducationPopover>
    </VStack>
  );
}
