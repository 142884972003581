import {
  Button,
  Flex,
  Icon,
  RadioProps,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { ChevronLeftIcon, RadioButton } from '@arena-labs/strive2-ui';

import { AppBanner } from '../../layout/app-banner';

export function DeviceOwnershipCheck({
  wearable,
  setSelection,
  isValid,
}: {
  isValid: boolean;
  wearable: string;
  setSelection: (selection: 'Connect OAuth' | 'Order Wearable') => void;
}) {
  const swiper = useSwiper();

  const { getRadioProps } = useRadioGroup({
    name: 'device_ownership',
    onChange: (deviceOwned: 'Connect OAuth' | 'Order Wearable') =>
      setSelection(deviceOwned),
  });

  return (
    <Flex
      direction={'column'}
      w={'100vw'}
      h="full"
      pt={'env(safe-area-inset-top)'}
    >
      <AppBanner shadow={'none'} color={'logo'}>
        <Button
          onClick={() => swiper.slidePrev()}
          variant={'unstyled'}
          size={'xs'}
        >
          <Icon as={ChevronLeftIcon} boxSize={4} />
        </Button>
        <Text textStyle={'h3'} gridColumn={2}>
          {wearable === 'oura'
            ? 'Oura Ring'
            : wearable === 'whoop'
            ? 'WHOOP Device'
            : 'Device Order'}
        </Text>
      </AppBanner>
      <Flex direction={'column'} h={'full'} px={6} mt={'20%'} gap={10}>
        <Text textStyle={'h2'}>
          Do you currently own{' '}
          {wearable === 'oura'
            ? 'an Oura Ring'
            : wearable === 'whoop'
            ? 'a WHOOP'
            : wearable === 'striveware'
            ? 'a StriveWare ring'
            : 'a device'}
          ?
        </Text>
        <Flex direction={'column'} gap={3}>
          <RadioButton
            variant={'secondary'}
            w={'full'}
            {...(getRadioProps({
              value: 'Connect OAuth',
            }) as RadioProps)}
          >
            Yes, I do
          </RadioButton>
          <RadioButton
            variant={'secondary'}
            {...(getRadioProps({
              value: `Order Wearable`,
            }) as RadioProps)}
            w={'full'}
          >
            No, I don&apos;t
          </RadioButton>
        </Flex>
        <Button
          variant={'primary'}
          w={'full'}
          mt={'auto'}
          mb={'calc(env(safe-area-inset-bottom) + 24px)'}
          onClick={() => {
            swiper.slideNext();
          }}
          disabled={!isValid}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
}
