import { Text } from '@chakra-ui/react';

import { WearableButton } from '../../wearable/wearable-button';
import { AppBanner, AppBannerProps } from '../app-banner';
import { MenuButton } from '../menu/menu-button';

export function HomeBanner({
  title,
  ...props
}: { title: string } & Omit<AppBannerProps, 'children'>) {
  return (
    <AppBanner sticky {...props}>
      <MenuButton color={'neutral.800'} mr={'10px'} />

      <Text
        textStyle={'copy_small'}
        mr={'auto'}
        color={'neutral.black'}
        bg={'#FFF7CC'}
        px={2}
        py={1}
        rounded={'full'}
      >
        {title}
      </Text>

      <WearableButton />
    </AppBanner>
  );
}
