import { Button, ButtonProps, Icon } from '@chakra-ui/react';

import { HorizontalBars } from '@arena-labs/strive2-ui';

import { useMenu } from './use-menu';

export function MenuButton({ ...props }: ButtonProps) {
  const appMenu = useMenu();
  return (
    <Button
      variant={'unstyled'}
      size={'xs'}
      onClick={() => appMenu.onOpen()}
      {...props}
    >
      <Icon as={HorizontalBars} boxSize={6} />
    </Button>
  );
}
