import { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  List,
  ListIcon,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { useAnimate, useReducedMotion } from 'framer-motion';

import {
  ChatIcon,
  LightbulbIcon,
  ToolkitIcon,
  TrendChartIcon,
  ZStack,
} from '@arena-labs/strive2-ui';

import { WelcomeLogoAnimation } from '../auth/welcome-logo-animation';

export type WelcomeProps = {
  isLoading: boolean;
  onProceed: () => void;
  userName: string | undefined;
};
export function Welcome({ onProceed, userName, isLoading }: WelcomeProps) {
  const dailyActions = [
    { expectation: 'Interpret and action your data', icon: TrendChartIcon },
    { expectation: 'Learn science-backed tools', icon: ToolkitIcon },
    {
      expectation: 'Integrate favorite tools into routine',
      icon: LightbulbIcon,
    },
    { expectation: 'Partner with your performance coach', icon: ChatIcon },
  ];

  const reducedMotion = useReducedMotion();

  const [scope, animate] = useAnimate();

  useEffect(() => {
    reducedMotion
      ? animate([
          ['#animationContainer', { opacity: [0, 1] }, { duration: 1.5 }],
          ['#logoContainer', { opacity: 0 }, { at: '<' }],
          ['#helloContainer', { opacity: 0 }, { at: '<' }],
        ])
      : animate([
          ['#logoContainer', { opacity: 0 }, { delay: 3 }],
          [
            '#helloContainer',
            { opacity: [0, 1, 1, 0] },
            { delay: 0.01, duration: 2 },
          ],
          ['#mainWelcome', { opacity: [0, 1], y: [20, 0] }, { duration: 1 }],
          [
            '#welcomeCopy',
            { opacity: [0, 1], y: [-20, 0] },
            { at: '<', duration: 1 },
          ],
          [
            '#expectationsBlock',
            { opacity: [0, 1] },
            { at: '-0.3', duration: 0.6 },
          ],
          [
            '#confirmButton',
            { opacity: [0, 1] },
            { at: '-0.3', duration: 0.6 },
          ],
        ]);
  }, [animate, reducedMotion]);

  return (
    <Box h={'full'} ref={scope}>
      <ZStack h="full" zIndex={0} id={'animationContainer'}>
        {/* Background Image and Filter Layer */}
        <Box
          w={'full'}
          h={'full'}
          bgImage={'./images/onboard/onboard-bg.webp'}
          bgSize={'cover'}
          bgPosition={'center'}
        >
          <Box
            bg={'neutral.black'}
            opacity={0.5}
            w={'full'}
            h={'full'}
            p={0}
          ></Box>
        </Box>

        <Flex direction="column" px="6" zIndex={1}>
          <ZStack my={'auto'}>
            <Box id={'logoContainer'}>
              <WelcomeLogoAnimation willAnimate={!reducedMotion} />
            </Box>

            <Flex
              direction={'column'}
              gap="3"
              textAlign={'center'}
              id={'helloContainer'}
              opacity={0}
            >
              <Text textStyle={'h2'} color={'neutral.white'}>
                Hi, {userName}
              </Text>

              <Text textStyle={'h3'} color={'neutral.400'}>
                The day has come!
              </Text>
            </Flex>

            <Flex
              direction={'column'}
              gap="3"
              textAlign={'center'}
              color={'neutral.white'}
            >
              <Text
                textStyle={'h1'}
                as={Flex}
                flexDirection={'column'}
                id={'mainWelcome'}
              >
                <span> Welcome to</span>
                <span>Arena Strive</span>
              </Text>

              <Text textStyle={'h3'} color={'neutral.400'} id={'welcomeCopy'}>
                Pursue flourishing through actionable tools
              </Text>
            </Flex>
          </ZStack>

          <Flex
            id="expectationsBlock"
            direction="column"
            gap="4"
            bg={'neutralAlpha.400'}
            px="4"
            py="3"
            borderRadius="card"
            w="full"
            textAlign="start"
          >
            <Text textStyle={'copy'} color={'neutral.white'}>
              What Can You Expect?
            </Text>
            <List
              textStyle={'copy_extra_small'}
              fontSize="p3"
              color="neutral.200"
              spacing="2"
            >
              {dailyActions.map((expectation, idx) => (
                <ListItem key={idx} verticalAlign="center" pb="1">
                  <ListIcon
                    as={expectation.icon}
                    boxSize="4"
                    color="primary.500"
                  />
                  <Text textStyle={'copy_small'} pl="1" as="span">
                    {expectation.expectation}
                  </Text>
                </ListItem>
              ))}
            </List>
          </Flex>

          <Button
            mt={'auto'}
            mb={'calc(env(safe-area-inset-bottom) + 16px)'}
            id={'confirmButton'}
            isLoading={isLoading}
            onClick={() => {
              onProceed();
            }}
            w="full"
            alignSelf="center"
            variant="primary"
          >
            Lets Get Started!
          </Button>
        </Flex>
      </ZStack>
    </Box>
  );
}
