import { Flex, Image, Text } from '@chakra-ui/react';

export function RecommendedFlag() {
  return (
    <Flex gap="2" align="center">
      <Image
        src="/images/learning-journey/bookmark-icon.webp"
        alt=""
        boxSize={20}
        marginRight="-8"
        zIndex="1"
      />
      <Text
        fontFamily="secondary"
        textStyle="h3"
        bg="warning.400"
        py="1"
        px="3"
        borderRadius="base"
      >
        RECOMMENDED
      </Text>
    </Flex>
  );
}
