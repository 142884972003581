import { useEffect } from 'react';
import { useMount } from 'react-use';
import NextLink from 'next/link';
import {
  Box,
  chakra,
  Flex,
  HStack,
  Link,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useAnimate } from 'framer-motion';

import { useHomepage } from '@arena-labs/strive2-coaching';
import { useResponsive } from '@arena-labs/strive2-ui';
import { $API, queryClient } from '@strive/api';
import { haptics } from '@strive/device';

import { AciCtaWidget } from '../aci/aci-cta-widget';
import { LogPracticeCard } from '../practice/log-practice-card';
import PillarCompleteModal from './practice-set-complete/pillar-complete-modal';
//here
export type PracticeFreeSpaceProps = {
  animate?: boolean;
  onAnimationComplete?: () => unknown;
};

export default function PracticeFreeSpace({
  animate = true,
  onAnimationComplete,
}: PracticeFreeSpaceProps) {
  const [homepage, { state, send }] = useHomepage();
  const learningSession = homepage?.learning_session;
  const rs = useResponsive();
  const explorationsStartsTomorrow =
    learningSession?.is_final_session_within_pillar &&
    learningSession.next_pillar_title === null;

  const pillarComplete = PillarCompleteModal.useDisclosure();
  const [scope, animates] = useAnimate();
  const pillarsKey = $API.getKeyByAlias('getPillars');
  const homepageKey = $API.getKeyByAlias('getHomepage');

  useEffect(() => {
    animate &&
      animates([
        [
          '#logPractice',
          { y: ['200px', '0px'], opacity: [0, 1] },
          { duration: 0.75, ease: 'easeOut', delay: 1 },
        ],
      ]).then(() => onAnimationComplete && onAnimationComplete());
  }, [animates, animate, onAnimationComplete]);

  useMount(() => {
    if (animate) {
      setTimeout(() => haptics.notification('SUCCESS'), 1000);
    }
  });

  const todaysTool = learningSession?.practices.at(-1);
  return (
    <Box mx={-6} h="full" ref={scope} overflowY={'auto'}>
      <Flex
        px={6}
        direction={'column'}
        gap={8}
        w="full"
        h="full"
        textAlign={'center'}
        pb="2"
        backgroundPosition={rs({ xs: 'center', base: 'bottom' })}
        backgroundSize={'cover'}
        bgImage={
          explorationsStartsTomorrow
            ? './images/celebration-background.webp'
            : 'none'
        }
      >
        {explorationsStartsTomorrow && (
          <Flex
            bg="neutralAlpha.100"
            rounded={'10px'}
            px={2}
            py={4}
            as={Flex}
            direction={'column'}
            gap={6}
            mt={3}
          >
            <Text textStyle={'h2'} as={Flex} flexDirection={'column'}>
              <span>Strive Foundations</span>
              <span>Completed!</span>
            </Text>
            <Text textStyle={'h3'} color={'neutral.600'}>
              Tomorrow, you will enter a self-guided journey called the{' '}
              <chakra.span color={'primary.800'}>
                Strive Explorations
              </chakra.span>
              . Don&apos;t miss out!
            </Text>
          </Flex>
        )}

        <Flex
          id={'logPractice'}
          flexDirection="column"
          gap="4"
          textAlign={'left'}
        >
          <HStack>
            <Text textStyle={'copy'} textAlign="start">
              Today’s Tool
            </Text>
            <Spacer />
            <Link
              as={NextLink}
              variant={'underline'}
              href="/practices"
              color={'neutral.800'}
              textStyle={'copy_small'}
            >
              View All
            </Link>
          </HStack>

          {todaysTool ? (
            <LogPracticeCard
              practice={todaysTool}
              highlightChecked
              onSuccess={() =>
                queryClient.invalidateQueries([pillarsKey, homepageKey])
              }
            />
          ) : null}

          <AciCtaWidget />
        </Flex>

        <PillarCompleteModal
          pillarTitle={homepage?.pillar_data?.current_pillar.short_title}
          pillarIconPath={homepage?.pillar_data?.current_pillar.icon}
          explorationsStartsTomorrow={explorationsStartsTomorrow}
          isOpen={state.matches('Pillar Complete Modal.open')}
          onClose={() => {
            send('Close Pillar Complete Modal');
            pillarComplete.onClose();
          }}
        />
      </Flex>
    </Box>
  );
}
