import Swiper from 'swiper';
import { useSwiper } from 'swiper/react';

import { WearableProviders } from '@strive/api';

import { StrivewareSetup } from '../wearable/striveware/setup/striveware-setup';
import { LaunchpadAuthorizeDataSync } from './launchpad-authorize-data-sync';
import { useUpdateLaunchpadChecklist } from './use-update-launchpad-checklist';

export type LaunchpadConnectWearableProps = {
  onClose: () => void;
  wearableProvider: WearableProviders;
};
export function LaunchpadConnectWearable({
  wearableProvider,
  onClose,
}: LaunchpadConnectWearableProps) {
  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onClose });
  const swiper = useSwiper() as Swiper | null; // Swiper is null if not in a swiper context

  const onBack = () => {
    if (swiper && swiper.slides.length > 1 && !swiper.isBeginning) {
      swiper.slidePrev();
    } else {
      onClose();
    }
  };

  if (wearableProvider === 'striveware') {
    return (
      <StrivewareSetup
        onCancel={() => {
          onBack();
        }}
        onComplete={() =>
          updateChecklist.mutate({
            slug: 'sync_wearable',
            status: 'completed',
          })
        }
      />
    );
  }

  return <LaunchpadAuthorizeDataSync onClose={onClose} onBack={onBack} />;
}
