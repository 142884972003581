import { Box, Button, Center, Flex, Icon, Text } from '@chakra-ui/react';
import { useMachine } from '@xstate5/react';

import { ChevronLeftIcon } from '@arena-labs/strive2-ui';

import { AppBanner } from '../layout/app-banner';
import { AppLayout } from '../layout/app-layout';
import { challengeSelectionMachine } from './challenge-selection-machine';
import { PracticeSetCard } from './practice-set-card';
import { PracticeSetCarousel } from './practice-set-carousel';
import { ViewAllChallenges } from './view-all-challenges';

export function PracticeSetSelection({ onBack }: { onBack: () => void }) {
  const [state, send] = useMachine(challengeSelectionMachine);

  return (
    <AppLayout
      banner={
        <AppBanner color={'neutral.800'}>
          <Button
            variant={'unstyled'}
            size={'xs'}
            textAlign={'left'}
            onClick={() => {
              state.matches('fullList') ||
              state.matches('challengeSelectedFromFullList')
                ? send({ type: 'Go Back' })
                : onBack();
            }}
          >
            <Center h={'full'}>
              <Icon as={ChevronLeftIcon} boxSize={4} mr={'auto'} />
            </Center>
          </Button>
          <Text as="h1" textStyle="h3" textAlign="center">
            Select Next Challenge
          </Text>
        </AppBanner>
      }
    >
      <Flex pt="4" px="6" h={'full'} direction="column" gap={6}>
        {state.matches('shortList') && (
          <PracticeSetCarousel
            onViewAll={() => send({ type: 'Click View All' })}
          />
        )}

        {state.matches('fullList') && (
          <ViewAllChallenges
            onClick={(challenge) =>
              send({
                type: 'User Select Challenge From Full List',
                selectedChallenge: challenge,
              })
            }
          />
        )}

        {state.matches('challengeSelectedFromFullList') &&
          state.context.selectedChallenge && (
            <Box h={'full'} pb={4}>
              <PracticeSetCard
                type={'pillar'}
                pillar={state.context.selectedChallenge}
                isActive
              />
            </Box>
          )}
      </Flex>
    </AppLayout>
  );
}
