import {
  Fade,
  Flex,
  Grid,
  Icon,
  LinkBox,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useHomepage } from '@arena-labs/strive2-coaching';
import {
  ChevronRightIcon,
  ProductEducationPopover,
  useEducationPopoverViewed,
  useGradient,
  useResponsive,
} from '@arena-labs/strive2-ui';

import { AciCtaWidget } from '../../aci/aci-cta-widget';
import { AppLayout } from '../../layout/app-layout';
import { HomeBanner } from '../../layout/home-banner/home-banner';

export function HomeHub({
  practiceSetName,
  willSelectPracticeSet,
  userName,
  practiceName,
}: {
  practiceSetName: string;
  willSelectPracticeSet: boolean;
  bgImagePath: string | undefined | null;
  userName?: string | undefined;
  practiceName?: string | undefined | null;
}) {
  const rs = useResponsive();
  const bgGradient = useGradient('background');
  const [_, { send }] = useHomepage();
  const hasViewedACIToolTip = useEducationPopoverViewed('ACI_Two')[0];

  return (
    <AppLayout
      banner={
        <HomeBanner shadow={'none'} title={practiceSetName} bg={bgGradient} />
      }
    >
      <Fade in style={{ height: '100%' }}>
        <Flex
          zIndex={-1}
          direction={'column'}
          h="full"
          pt={rs({ xs: 2, base: 4 })}
          pb="6"
          px="6"
          gap={'32px'}
        >
          <Text textStyle={'h3'} display={'flex'} flexDir={'column'}>
            <span data-testid={'greeting-text'}>{getGreeting()},</span>
            <span data-testid={'greeting-name'}>{userName ?? 'welcome'}.</span>
          </Text>

          <VStack>
            <Text textStyle={'copy_bold'} mr={'auto'}>
              Daily Lesson
            </Text>

            <ProductEducationPopover
              id={'Choose_Challenge'}
              placement={'bottom'}
              shouldRender={willSelectPracticeSet && hasViewedACIToolTip}
              title={'Select Next Challenge'}
              body={
                'The power is yours! What set of tools do you want to unlock next?'
              }
            >
              <LinkBox
                w={'full'}
                as={'button'}
                onClick={() => {
                  send('Close Landing');
                }}
                bg={'neutral.white'}
                rounded={'card'}
                overflow={'hidden'}
                shadow={'1dp'}
                zIndex={0}
              >
                <Grid
                  p={4}
                  templateColumns={'1fr auto'}
                  w={'full'}
                  zIndex={2}
                  alignContent={'center'}
                >
                  <VStack align={'flex-start'} w={'full'}>
                    <Text
                      textStyle={'h5'}
                      color={'neutral.black'}
                      flexWrap={'nowrap'}
                    >
                      {willSelectPracticeSet
                        ? 'Select Next Challenge'
                        : `Learn ${practiceName}`}
                    </Text>

                    <Text textStyle={'copy_small'} color={'neutral.600'}>
                      5 min
                    </Text>
                  </VStack>
                  <Icon as={ChevronRightIcon} boxSize={4} mr={2} mt={2} />
                </Grid>
              </LinkBox>
            </ProductEducationPopover>
          </VStack>

          <AciCtaWidget />
        </Flex>
      </Fade>
    </AppLayout>
  );
}

function getGreeting() {
  const currentHour = new Date().getHours();
  return currentHour < 12
    ? 'Good Morning'
    : currentHour < 18
    ? 'Good Afternoon'
    : 'Good Evening';
}
