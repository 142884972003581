import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { stagger, useAnimate } from 'framer-motion';

import { Icon, StriveLogoNew } from '@arena-labs/strive2-ui';

export type WelcomeLogoAnimationProps = {
  willAnimate?: boolean;
};

export function WelcomeLogoAnimation({
  willAnimate = true,
}: WelcomeLogoAnimationProps) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    willAnimate &&
      animate([
        [
          'path',
          { opacity: [0, 1] },
          { at: '<', duration: 0.6, delay: stagger(0.4) },
        ],
        ['p', { opacity: [0, 1] }, { duration: 2, at: '-1.0' }],
      ]);
  }, [animate, willAnimate]);

  return (
    <Box ref={scope} w={'full'} textAlign={'center'}>
      <Icon as={StriveLogoNew} w={'275px'} h={'auto'} color={'logo'} />
    </Box>
  );
}
