import { chakra, Grid, Text } from '@chakra-ui/react';

import { Icon, IconProps } from '@arena-labs/strive2-ui';

type ChecklistItemProps = {
  label: string;
  icon: IconProps['as'];
  status: 'active' | 'completed' | 'skipped' | 'locked';
  onClick?: () => void;
  duration?: number;
  incompleteLabel?: string | null;
};

export function ChecklistItem({
  label,
  icon,
  status,
  duration,
  onClick,
  incompleteLabel = 'Not Completed',
}: ChecklistItemProps) {
  const Wrapper = status === 'active' ? chakra.button : chakra.div;
  const wrapperProps = status === 'active' ? { onClick } : {};

  return (
    <Wrapper
      bg={status === 'active' ? 'white' : 'transparent'}
      border={'1px solid'}
      borderColor={'neutralAlpha.200'}
      shadow={status === 'completed' ? 'none' : '1dp'}
      textAlign="left"
      borderRadius="8px"
      py="4"
      px="4"
      {...wrapperProps}
    >
      <Grid templateColumns="auto 1fr" alignItems="center" gap="1">
        <Icon as={icon} color="primary.400" />
        <Text textStyle="copy" color="neutral.800">
          {label}
        </Text>
        {status === 'active' && duration ? (
          <Text textStyle="copy_extra_small" color="neutral.600" gridColumn="2">
            {duration} min
          </Text>
        ) : null}
        {status === 'completed' ? (
          <Text textStyle="copy_extra_small" color="success.300" gridColumn="2">
            Completed
          </Text>
        ) : incompleteLabel ? (
          <Text textStyle="copy_extra_small" color="error.300" gridColumn="2">
            {incompleteLabel}
          </Text>
        ) : null}
      </Grid>
    </Wrapper>
  );
}
