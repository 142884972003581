import { Button, Image, Text, VStack } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { useGradient } from '@arena-labs/strive2-ui';
import { WearableProviders } from '@strive/api';

import { LaunchpadActionText } from '../launchpad-action-text';
import { LaunchpadActionView } from '../launchpad-action-view';

export function SizeSelectionWelcome({
  onClose,
  wearableProvider,
}: {
  wearableProvider: WearableProviders;
  onClose: () => void;
}) {
  const swiper = useSwiper();
  const bgGradient = useGradient('background');

  const strivewareHero = (
    <VStack spacing={10}>
      <Text textStyle={'h1'} color={'neutral.800'}>
        StriveWare
      </Text>

      <Image
        w={'full'}
        src={'./images/striveware/black-ring-grey-light-left.webp'}
        alt={'Ring Sizing Kit'}
        maxW={'130px'}
        mb={8}
        mx={'auto'}
      />
    </VStack>
  );
  const ouraHero = (
    <Image
      src={'./images/launchpad/ring-sizing-kit.webp'}
      alt={'Oura Sizing Kit'}
      maxW={'325px'}
      mb={8}
    />
  );

  const strivewareTextEntries = (
    <LaunchpadActionText
      boldText={'Request StriveWare Now'}
      bodyText={`Ready for the real deal? Share your ring size now, and we'll promptly order your StriveWare. Get ready for excitement!`}
    />
  );
  const ouraTextEntries = (
    <LaunchpadActionText
      boldText={'Claim Your Oura Ring Now'}
      bodyText={`Ready for the real deal? Share your ring size now, and we'll promptly order your Oura Ring. Get ready for excitement!`}
    />
  );
  return (
    <LaunchpadActionView
      gradient={bgGradient}
      onBack={onClose}
      hero={wearableProvider === 'striveware' ? strivewareHero : ouraHero}
      textBox={
        wearableProvider === 'striveware'
          ? strivewareTextEntries
          : ouraTextEntries
      }
      footer={
        <Button
          variant={'primary'}
          w={'full'}
          onClick={() => swiper.slideNext()}
        >
          Get Started
        </Button>
      }
    />
  );
}
