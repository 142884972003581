import Head from 'next/head';
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { z } from 'zod';

import { AnalyticsContext } from '@arena-labs/analytics';
import { useHomepage } from '@arena-labs/strive2-coaching';
import { useGradient, WaitForQuery } from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';
import { createDataStore, useDataStore } from '@strive/utils';

import { TeamVideoModal } from '../home/team-video-modal';
import { AppLayout } from '../layout/app-layout';
import { HomeBanner } from '../layout/home-banner/home-banner';
import { LaunchpadChecklist } from './checklist';
import { LaunchpadCountdown } from './countdown';
import { LaunchpadDates } from './launchpad-dates';
import { LaunchpadFooter } from './launchpad-footer';
import { LaunchpadWelcome } from './launchpad-welcome';

const launchpadHomeStatus = createDataStore({
  key: 'launchpadHomeStatus',
  schema: z.enum(['entered']).nullable(),
});

export function LaunchPadHome() {
  const [, setStatus, { query: statusQuery }] =
    useDataStore(launchpadHomeStatus);
  const [_, { state, send }] = useHomepage();
  const bgGradient = useGradient('background');

  const launchpadQuery = $API.useGetLaunchpad(
    {},
    // Make sure to show the latest data when returning to the app
    { refetchOnMount: 'always', refetchOnWindowFocus: 'always' },
  );

  return (
    <>
      <WaitForQuery query={statusQuery} loading={undefined}>
        {(status) => (
          <AppLayout
            banner={<HomeBanner title={'Orientation'} bg={bgGradient} />}
            footer={<LaunchpadFooter />}
            contentProps={{ background: bgGradient }}
          >
            <Head>
              <title>Arena Strive: Orientation</title>
            </Head>
            <AnalyticsContext context={{ learningState: 'launchpad' }}>
              <Flex direction="column" px="6" py="8" gap="8">
                <WaitForQuery
                  query={launchpadQuery}
                  fallbackErrorMessage="There was a problem loading the launchpad"
                >
                  {(launchpad) => {
                    return (
                      <>
                        <LaunchpadCountdown
                          startDate={launchpad.cohort_launch_at}
                        />

                        <LaunchpadChecklist
                          launchpad={launchpad}
                          reload={launchpadQuery.invalidate}
                        />

                        <LaunchpadDates
                          startDate={launchpad.cohort_launch_at}
                          events={launchpad.launch_events}
                        />
                      </>
                    );
                  }}
                </WaitForQuery>
              </Flex>

              <Modal
                isOpen={status === null}
                scrollBehavior="inside"
                onClose={() => void 0}
              >
                <ModalOverlay />
                <ModalContent mx="0" m="0" maxHeight="full">
                  <ModalBody p="0">
                    <LaunchpadWelcome onEnter={() => setStatus('entered')} />
                  </ModalBody>
                </ModalContent>
              </Modal>

              <TeamVideoModal
                isOpen={
                  state.matches('Team Video.open') && status === 'entered'
                }
                onClose={() => {
                  send('Close Team Video Modal');
                }}
              />
            </AnalyticsContext>
          </AppLayout>
        )}
      </WaitForQuery>
    </>
  );
}
