import Head from 'next/head';
import { Text } from '@chakra-ui/react';

import { useHomepage } from '@arena-labs/strive2-coaching';
import { LoadingOrError, PageStack, useGradient } from '@arena-labs/strive2-ui';

import { ExplorationsWelcome } from '../explorations/explorations-welcome';
import { AppLayout } from '../layout/app-layout';
import { HomeBanner } from '../layout/home-banner/home-banner';
import { ProgramCards } from './program-cards';
import { ProgramWidget } from './program-widget';
import { TeamVideoModal } from './team-video-modal';

export function CompleteHome() {
  const [homepage, { state, send }] = useHomepage();
  const completeData = homepage?.complete_data;
  const bgGradient = useGradient('background');
  if (!homepage || !completeData) {
    return (
      <LoadingOrError
        status={'error'}
        errorMessage="There was an error loading Home."
      />
    );
  }

  return (
    <AppLayout
      banner={
        state.matches('Explorations Welcome.Active') ? null : (
          <HomeBanner title="Explorations" bg={bgGradient} />
        )
      }
      contentProps={{ background: bgGradient }}
    >
      <Head>
        <title>Arena Strive | Explorations</title>
      </Head>
      <PageStack w="full" spacing="4">
        {state.matches('Explorations Welcome.Active') ? (
          <ExplorationsWelcome
            onContinue={() => send('Close Explorations Welcome Modal')}
          />
        ) : (
          <>
            <Text textStyle={'h3'}>Self Guided Programs</Text>
            {completeData.program_data && (
              <ProgramWidget program={completeData.program_data} />
            )}
            <ProgramCards />
            <TeamVideoModal
              isOpen={state.matches('Team Video.open')}
              onClose={() => send('Close Team Video Modal')}
            />
          </>
        )}
      </PageStack>
    </AppLayout>
  );
}
