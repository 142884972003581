import { Box, Circle, Flex, Icon, Text, VStack } from '@chakra-ui/react';

import { isMediaPlayedThisSession } from '@arena-labs/shared-models';
import { useHomepage } from '@arena-labs/strive2-coaching';
import { PenIcon, ZStack } from '@arena-labs/strive2-ui';

export function SessionTracker() {
  const [homepage] = useHomepage();

  if (!homepage?.learning_session?.media_items) {
    return null;
  }
  const mediaItems = homepage?.learning_session?.media_items ?? [];

  //grab any loom videos in the current lesson
  const loomVideos = mediaItems.filter(
    (
      mediaItem,
    ): mediaItem is { type: 'loom'; loom_id: string; watched: boolean } =>
      mediaItem.type === 'loom',
  );

  const completedLooms = loomVideos.filter((loom) =>
    isMediaPlayedThisSession(loom),
  );

  //remove loom videos froms the current lesson
  const mediaWithoutLooms = mediaItems.filter((item) => item.type !== 'loom');
  //grab any videos/audios that are either 'practice_intro' or 'practice_coach_me'
  const lessons = mediaWithoutLooms.filter(
    (media) =>
      (media.type === 'audio' || media.type === 'video') &&
      (media.category === 'practice_intro' ||
        media.category === 'practice_coach_me'),
  );
  const completedLessons = lessons.filter((lesson) =>
    isMediaPlayedThisSession(lesson),
  );

  //match the practice_intro to the practice to make sure the counter is correct
  const introLesson = lessons.find(
    (lesson) =>
      (lesson.type === 'audio' || lesson.type === 'video') &&
      lesson.category === 'practice_intro',
  );

  const currentPractice = homepage.learning_session.practices.find(
    (practice) =>
      (introLesson?.type === 'audio' || introLesson?.type === 'video') &&
      practice.intro.title === introLesson?.title,
  );
  const currentPracticeLogCount = currentPractice?.log_count;

  return (
    <>
      <Flex direction={'row'} justify={'space-between'} w="full" px={6} gap={6}>
        <TrackerCircle
          title={'Lesson'}
          activated
          complete={lessons.length === completedLessons.length}
        >
          <Text textStyle={'copy_small'}>
            {completedLessons.length}/{lessons.length}
          </Text>
        </TrackerCircle>

        {loomVideos.length ? (
          <TrackerCircle
            title={'Coach Note'}
            activated={lessons.length === completedLessons.length}
            complete={completedLooms.length === loomVideos.length}
            progress={completedLessons.length / lessons.length}
          >
            <Text textStyle={'copy_extra_small'}>
              {completedLooms.length}/{loomVideos.length}
            </Text>
          </TrackerCircle>
        ) : null}

        <TrackerCircle
          title={'Tool'}
          activated={
            loomVideos.length
              ? loomVideos.length === completedLooms.length
              : lessons.length === completedLessons.length
          }
          progress={
            loomVideos.length
              ? completedLooms.length / loomVideos.length
              : completedLessons.length / lessons.length
          }
          complete={
            currentPracticeLogCount && currentPracticeLogCount > 0
              ? true
              : false
          }
        >
          <Icon as={PenIcon} boxSize={4} />
          {/* <Text textStyle={'copy_small'}>{currentPracticeLogCount}/1</Text> */}
        </TrackerCircle>
      </Flex>
    </>
  );
}

type TrackerCirleProps = {
  title: string;
  children: React.ReactNode;
  hasSpacer?: boolean;
  activated: boolean;
  complete: boolean;
  progress?: number;
};
function TrackerCircle({
  title,
  children,
  activated,
  complete,
  progress,
}: TrackerCirleProps) {
  return (
    <>
      <ZStack
        sx={{
          ':first-of-type': {
            display: 'none',
          },
        }}
        my={4}
        w="full"
      >
        <Box w={'full'} h="2px" bg={'neutral.400'} rounded={'full'} />
        <Box
          w={`${(progress ?? 0) * 100}%`}
          h="2px"
          bg={'primary.200'}
          rounded={'full'}
        />
      </ZStack>

      <VStack
        spacing={2}
        textAlign={'center'}
        color={activated ? 'logo' : 'neutral.400'}
      >
        <Circle
          size={10}
          bg={activated ? 'primary.200' : 'none'}
          border={'1px solid'}
          borderColor={
            activated && !complete
              ? 'primary.400'
              : activated
              ? 'neutral.400'
              : 'neutral.400'
          }
        >
          {children}
        </Circle>
        <Text textStyle={'copy_small'} w={'60px'}>
          {title}
        </Text>
      </VStack>
    </>
  );
}
