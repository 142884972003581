import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { Program, ProgramUpNextCard } from '@arena-labs/strive2-content';

import { getProgramPage, getVideoPage } from '../../lib/utils';

export type ProgramWidgetProps = {
  program: Program;
};

export function ProgramWidget({ program }: ProgramWidgetProps) {
  return (
    <Flex gap={2} direction="column" w="full">
      <Text textStyle={'copy_small'} color="neutral.600">
        {' '}
        Program In-Progress
      </Text>
      <ProgramUpNextCard
        program={program}
        href={getProgramPage(program)}
        video={program.next_video}
        getVideoPage={(v) => getVideoPage(v, { program: program.slug })}
        headingLevel="h3"
      />
    </Flex>
  );
}
