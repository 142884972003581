import { Flex, List, ListIcon, ListItem, Text } from '@chakra-ui/react';

import { CheckedBoxIcon } from '@arena-labs/strive2-ui';

const dailyActions = [
  'Ongoing Access to Data Insights',
  'Join a Self-Guided Program',
  'Stay Consistent with your Favorite Tools',
];

export function ExplorationsActionList() {
  return (
    <Flex
      direction="column"
      gap="4"
      bg="neutral.200"
      px="4"
      py="3"
      borderRadius="card"
      w="full"
      textAlign="start"
    >
      <Text textStyle={'copy_bold'}>Suggested Daily Actions:</Text>
      <List color="neutral.200" spacing="2">
        {dailyActions.map((action, idx) => (
          <ListItem key={idx} verticalAlign="center" pb="1">
            <ListIcon as={CheckedBoxIcon} boxSize="3" color="primary.700" />
            <Text
              color={'neutral.600'}
              textStyle={'copy_small'}
              pl="1"
              as="span"
            >
              {action}
            </Text>
          </ListItem>
        ))}
      </List>
    </Flex>
  );
}
