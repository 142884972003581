import { Flex, Text } from '@chakra-ui/react';

import { ProgramCard } from '@arena-labs/strive2-content';
import { $API } from '@strive/api';

import { getProgramPage } from '../../lib/utils';

export function ProgramCards() {
  const { data: programs } = $API.useGetPlaylists({
    queries: { type: 'explorations' },
  });

  return (
    <Flex gap={2} direction="column">
      <Text textStyle={'copy_small'} color="neutral.500">
        Select Another Program:
      </Text>
      {programs?.groups.map((program) => {
        return (
          <ProgramCard
            key={program.slug}
            program={program}
            href={getProgramPage(program)}
          />
        );
      })}
    </Flex>
  );
}
