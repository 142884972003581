import { Button, Image, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { LaunchpadActionView } from '../launchpad-action-view';

export function UserCommitedView({
  onClose,
  isLoading,
}: {
  onClose: () => void;
  isLoading: boolean;
}) {
  const swiper = useSwiper();

  return (
    <LaunchpadActionView
      onBack={() => swiper.slidePrev()}
      hero={
        <Image
          mt={6}
          mx={'auto'}
          w={'full'}
          src={'./images/launchpad/screens-high-five.webp'}
          alt={'Meaning Conversation With a Coach'}
        />
      }
      textBox={
        <>
          <Text textStyle={'h2'} mb={4}>
            You’re Committed!
          </Text>
          <Text textStyle={'copy'} lineHeight={'tall'}>
            Start by exploring the video playlists under active tasks to
            familiarize yourself with what Arena Strive has to offer!
          </Text>
        </>
      }
      footer={
        <Button
          isLoading={isLoading}
          variant={'primary'}
          w={'full'}
          onClick={() => onClose()}
        >
          Home
        </Button>
      }
    />
  );
}
