import { chakra, Flex } from '@chakra-ui/react';

import { Markdown } from '@arena-labs/strive2-ui';

import { LockedChecklistItem } from './locked-checklist-item';
import { SectionHeader } from './section-header';

export type LaunchpadDatesProps = {
  startDate: Date;
  events: string;
};

export function LaunchpadDates({ startDate, events }: LaunchpadDatesProps) {
  const formattedStartDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(startDate);

  return (
    <Flex direction="column" gap="8">
      <SectionHeader label="Save the Dates" />

      <chakra.dl>
        <chakra.dt textStyle={'copy_small'} color="neutral.600" mb="2">
          {formattedStartDate}
        </chakra.dt>
        <chakra.dd>
          <LockedChecklistItem label="Strive Foundations Begins" />
        </chakra.dd>

        <chakra.dt textStyle={'copy_small'} color="neutral.600" mt="8" mb="2">
          In-person Launch Event
        </chakra.dt>
        <chakra.dd>
          <Markdown
            textStyle="copy"
            tags={['details', 'summary', 'b', 'i', 'u', 'small', 'mark']}
            sx={{
              h3: {
                textStyle: 'copy',
                color: 'secondary.700',
                fontWeight: 'bold',
              },
              mark: {
                color: 'logo',
                bg: 'transparent',
              },
              small: {
                textStyle: 'copy',
              },
              'small small': {
                textStyle: 'copy_small',
              },
            }}
          >
            {events}
          </Markdown>
        </chakra.dd>
      </chakra.dl>
    </Flex>
  );
}
