import { Capacitor } from '@capacitor/core';
import {
  Box,
  Button,
  Circle,
  Flex,
  Image,
  RadioProps,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { RadioButton, useGradient } from '@arena-labs/strive2-ui';
import { WearableProviders } from '@strive/api';

import { LaunchpadActionView } from '../launchpad-action-view';

export function SelectSize({
  selection,
  onSelect,
  wearableProvider,
}: {
  selection: number | undefined;
  onSelect: (selection: number) => void;
  wearableProvider: WearableProviders;
}) {
  const platform = Capacitor.getPlatform();
  const swiper = useSwiper();

  const bgGradient = useGradient('background');
  const selectedButtonGradient = useGradient('secondary.light');

  const { getRadioProps } = useRadioGroup({
    name: 'ringSize',
    onChange: (nextValue: string) => {
      onSelect(Number(nextValue));
    },
  });
  //the first element is placed at the 3 o'clock position, ordered here to match design
  let buttonNames = [8, 9, 10, 11, 12, 13, 6, 7];
  const diameter = 275;
  const radius = diameter / 2;

  if (wearableProvider === 'striveware') {
    buttonNames = buttonNames.filter((name) => name !== 6);
  }
  //this should work for any size circle and any amount of buttons
  const calculateButtonPosition = (index: number, totalButtons: number) => {
    const angle = (index / totalButtons) * 360;
    const angleRadian = (angle * Math.PI) / 180;

    const x = radius * Math.cos(angleRadian);
    const y = radius + radius * Math.sin(angleRadian);

    return {
      top: `${y}px`,
      left: `${platform === 'ios' ? radius + x : x}px`,
    };
  };
  return (
    <LaunchpadActionView
      gradient={bgGradient}
      onBack={() => swiper.slidePrev()}
      hero={
        //the width and height here act to draw the bounds of our hypotetical circle where the buttons will be placed
        <>
          <Flex direction={'column'}>
            <Text textStyle={'h2'} mr={'auto'} color={'neutral.800'}>
              Select the Size
            </Text>
            <Text textStyle={'copy'} mr={'auto'} color={'neutral.600'}>
              Press the circle to select your size.
            </Text>
          </Flex>
          <Box
            w={`${diameter}px`}
            h={`${diameter}px`}
            position={'relative'}
            mx={'auto'}
            mt={4}
          >
            {buttonNames.map((buttonName, index) => {
              return (
                <RadioButton
                  key={buttonName}
                  radio={{
                    p: 0,
                    w: 'auto',
                    height: 'auto',
                    shadow: 'none',
                    position: 'absolute',
                    //correct offset of buttons
                    transform: `translateX(-50%)`,
                    ...calculateButtonPosition(index, buttonNames.length),
                    _checked: {},
                  }}
                  variant={'unstyled'}
                  {...(getRadioProps({ value: buttonName }) as RadioProps)}
                >
                  <Flex direction={'column'} gap={2}>
                    <Text textStyle={'copy_small'} color={'neutral.800'}>
                      {buttonName}
                    </Text>
                    <Circle
                      size={16}
                      bgGradient={
                        selection === buttonName
                          ? selectedButtonGradient
                          : 'linear-gradient(neutral.300, neutral.400)'
                      }
                      border={'3px solid'}
                      borderColor={
                        selection === buttonName ? 'primary.700' : 'neutral.300'
                      }
                    >
                      <Image
                        src={'./images/launchpad/ring-selection.webp'}
                        w={`${Number(buttonName) * 4}px`}
                        alt="oura ring"
                      />
                    </Circle>
                  </Flex>
                </RadioButton>
              );
            })}
          </Box>
        </>
      }
      footer={
        <Button
          disabled={!selection}
          variant={'primary'}
          w={'full'}
          onClick={() => swiper.slideNext()}
        >
          Confirm
        </Button>
      }
    />
  );
}
