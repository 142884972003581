import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  SlideFade,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';

import { ToolKitTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { isMediaPlayed, PillarPractice } from '@arena-labs/shared-models';
import { ToolkitIcon } from '@arena-labs/strive2-ui';
import { $API, queryClient } from '@strive/api';

import { LogPracticeCard } from '../practice/log-practice-card';
import { useSkipPractice } from '../practice/use-log-practice';

type LogCurrentPracticeProps = {
  practice: PillarPractice | undefined;
};

export default function LogCurrentPractice({
  practice,
}: LogCurrentPracticeProps) {
  const skipPractice = useSkipPractice({ retry: 3 });
  const analytics = useAnalytics();
  const pillarsKey = $API.getKeyByAlias('getPillars');
  const homepageKey = $API.getKeyByAlias('getHomepage');

  if (!practice || !isMediaPlayed(practice.intro)) {
    return null;
  }

  return (
    <Flex direction="column" align="stretch" gap={4} w="full" h="full">
      <HStack>
        <Icon as={ToolkitIcon} boxSize={6} color="icon.tertiary" />
        <Text textStyle={'h3'}>Complete this tool</Text>
      </HStack>
      <Box color={'neutral.600'}>
        <Text textStyle={'copy_small'} lineHeight={'taller'}>
          Try the new tool once after each Lesson.
        </Text>
        <HStack>
          <Text textStyle={'copy_small'}>Check the</Text>
          <Box
            h="18px"
            w="18px"
            borderRadius={'sm'}
            border="2px solid"
            borderColor={'primary.700'}
          >
            <VisuallyHidden>checkbox</VisuallyHidden>
          </Box>
          <Text textStyle={'copy_small'}>to move on </Text>
        </HStack>
      </Box>

      <LogPracticeCard
        practice={practice}
        onSuccess={() =>
          queryClient.invalidateQueries([pillarsKey, homepageKey])
        }
      />

      <Box mt="auto">
        <SlideFade in offsetY="50px" transition={{ enter: { delay: 2 } }}>
          <Flex
            direction="column"
            bg={'neutral.white'}
            gap={4}
            borderRadius="card"
            p="4"
          >
            <Text textStyle={'h5'} textAlign="center" mb="4">
              Not a good time?
            </Text>
            <Button
              variant="primary"
              w="full"
              isLoading={skipPractice.isLoading}
              onClick={() => {
                queryClient.invalidateQueries(pillarsKey);
                analytics.logEvent(ToolKitTrackingEvent.ToolSkipped);
                skipPractice.mutate({ practice: practice.slug });
                queryClient.invalidateQueries([pillarsKey, homepageKey]);
              }}
            >
              Skip the tool
            </Button>
          </Flex>
        </SlideFade>
      </Box>
    </Flex>
  );
}
