import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react';

import {
  CalendarIcon,
  ChatIcon,
  ChecklistIcon,
  ClockIcon,
  Icon,
  MotionBox,
} from '@arena-labs/strive2-ui';

export type LaunchpadWelcomeProps = {
  onEnter: () => void;
};

export function LaunchpadWelcome({ onEnter }: LaunchpadWelcomeProps) {
  return (
    <Box
      h="100vh"
      position="relative"
      bgImage="/images/launchpad/welcome.webp"
      bgSize="cover"
    >
      <Box h="full" bg="#1E2425EB">
        <Flex
          zIndex="1000"
          direction="column"
          h="full"
          px="6"
          pt="calc(24px + env(safe-area-inset-top))"
          pb="calc(24px + env(safe-area-inset-bottom))"
          gap="12"
        >
          <MotionBox
            mt="25vh"
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: 0,
            }}
          >
            <Text as="h2" textStyle="h2" color="neutral.white">
              Welcome to Orientation
            </Text>
            <Text textStyle={'copy_small'} color="neutral.400" mt="4">
              Getting to A Higher Standard starts with getting organized.
            </Text>
          </MotionBox>

          <MotionBox
            bg="neutralAlpha.600"
            borderRadius="base"
            display="flex"
            flexDirection="column"
            gap="4"
            p="4"
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: 2,
            }}
          >
            <Text textStyle="copy" color={'neutral.white'}>
              What Awaits You?
            </Text>
            <Grid templateColumns="auto 1fr" color="neutral.white" gap="4">
              <Icon as={ChecklistIcon} color="primary.500" />
              <Text textStyle={'copy_small'}>
                Clear Actions to Get Oriented
              </Text>

              <Icon as={ChatIcon} color="primary.500" />
              <Text textStyle={'copy_small'}>Personalized Chat Support</Text>

              <Icon as={ClockIcon} color="primary.500" />
              <Text textStyle={'copy_small'}>Brief Time-Bounded Actions</Text>

              <Icon as={CalendarIcon} color="primary.500" />
              <Text textStyle={'copy_small'}>Timeline to Launch</Text>
            </Grid>
          </MotionBox>
          <MotionBox
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: 4,
            }}
            mt="auto"
            w="full"
          >
            <Button variant="primary" onClick={onEnter} w="full">
              Enter Orientation
            </Button>
          </MotionBox>
        </Flex>
      </Box>
    </Box>
  );
}
