import { useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { formatDuration, intervalToDuration, isBefore } from 'date-fns';

import { roundDuration } from '@arena-labs/strive2-ui';

export type LaunchpadCountdownProps = {
  startDate: Date;
};

export function LaunchpadCountdown({ startDate }: LaunchpadCountdownProps) {
  const timeRemaining = useMemo(() => {
    if (isBefore(startDate, new Date())) return '00:00:00';

    const diff = intervalToDuration({ start: new Date(), end: startDate });
    const duration = roundDuration(diff);
    return formatDuration(duration);
  }, [startDate]);

  return (
    <Flex
      direction="column"
      gap="3"
      align="center"
      bg={'information.50'}
      position="relative"
      borderRadius="base"
      padding="4"
    >
      <Text textStyle="h3" color="neutral.600">
        {timeRemaining}
      </Text>
      <Text textStyle="copy">until Strive begins</Text>
    </Flex>
  );
}
